$color-shadow: hsl(240, 4%, 10%);
$color-shadowStrong: hsl(240, 4%, 5%);
$color-white: hsl(240, 4%, 15%);
$color-whiteStrong: hsl(240, 4%, 20%);
$color-pink: #ff557c;
$ruler: 16px;

.portfolio-section {
  .portfolio-content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 120px;

    a {
      text-decoration: none;
      color: $color-pink;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: .5rem;
      width: 95%;
      transition: all 0.1s ease-in-out;
      border-radius: 10px;
      cursor: pointer;
      text-shadow: none;
      padding: 10px;
      background: $color-pink;
      color: azure;

      &:hover {
        background: #af1958;

      }

      &:active {
        box-shadow: inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-white;
      }
    }

    .buttonGroup {
      margin-top: 1rem;
      display: flex;
      gap: 1rem;
      justify-content: center;
    }

    .portfolio-info-area {
      min-height: 200px;
      flex-basis: 50%;
      padding: 1rem;
      box-sizing: border-box;

      .portfolio-info {
        background: #37383a;
        height: 100%;
        border-radius: 10px;
        position: relative;
        padding: 1.5rem;
        box-sizing: border-box;
        text-shadow: none;

        .picture-area {
          height: 300px;
          background-image: url("../images/1.png");
          border-radius: 10px 10px 0 0;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          top: 0;
          left: 0;
          width: 100%;
          position: absolute;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .title {
          margin: 290px 0 10px 0;
          font-size: 30px;
          font-weight: bold;
          color: white;
        }

        .contents {
          line-height: 1.5rem;
          color: rgb(190, 190, 190);
          text-shadow: none;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .portfolio-section {
    .portfolio-content {
      .portfolio-info-area {
        flex-basis: 100%;
        padding: 10px 20px;
      }
    }
  }
}