$color-pink: rgb(255, 85, 124, 0.5);
$color-lightgray: rgb(139, 139, 139);

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px) rotate(0deg);
  }

  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-10px) rotate(5deg) skew(2deg) scale(1.05);
  }

  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px) rotate(0deg);
  }
}

.about-section {
  margin-bottom: 40px;

  .about-content {
    display: flex;
    gap: 2rem;
    margin: 0 1.5rem;

    .picture-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      .info-li {
        display: flex;
        margin-left: -1.5rem;
        .icon{
          padding-right: .5rem;
        }
      }
    }

    .about-picture {
      box-sizing: border-box;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.322);
      //margin: 2rem;
      width: 100%;
      border-radius: 50rem;
      overflow: hidden;
      animation: float 4s ease-in-out infinite;
      animation-play-state: paused;

      &:hover {
        animation-play-state: running;
      }
    }

    .about-info {
      margin: 30px;
      max-width: 600px;
      min-width: 300px;
      box-sizing: border-box;

      .info-title {
        font-size: 30px;
        margin: 0 auto;
        display: block;
        text-align: center;
        color: rgb(255, 85, 124, 1);
        font-weight: bolder;
      }

      .info {
        font-size: 16px;
        color: $color-lightgray;
        font-weight: 600;
        text-shadow: none;
      }

      ul {
        padding: 20px 0;

        li {
          height: 40px;

          a {
            color: inherit;
            text-decoration: none;

            &:hover {
              color: $color-pink;

              .icon {
                color: $color-pink;
              }
            }


          }

          .info-li {
            display: flex;
            gap: 1rem;

            .icon {
              font-size: 20px;
              color: $color-lightgray;
            }

            text-shadow: none;
            color: $color-lightgray;
          }
        }
      }
    }
  }
}

@media screen and (max-width : 768px) {
  .about-section {
    .about-content {
      flex-wrap: wrap;
      justify-content: center;
      margin: 2rem;

      .about-picture {
        height: 125%;
        width: 125%;
        margin: 0 -2rem;

        img {
          width: 100%;
          height: auto;
        }
      }

      .picture-wrap {
        border-radius: 50rem;
        overflow: hidden;
        height: 15rem;
        width: 15rem;
      }
    }
  }
}