$color-shadow: hsl(240, 4%, 10%);
$color-shadowStrong: hsl(240, 4%, 5%);
$color-white: hsl(240, 4%, 15%);
$color-whiteStrong: hsl(240, 4%, 20%);
$color-pink: rgb(255, 85, 124);
$color-red: #ae1100;
$ruler: 16px;
.skills-section {
  margin-bottom: 120px;
  .skills-content {
    .skill-title {
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      margin-top: 30px;
    }
    .skills-infos {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .skill-group {
        .skill {
          background: rgb(11, 11, 11);
          background: linear-gradient(315deg, $color-shadow 0%, $color-white 100%);
          width: 100px;
          height: 100px;
          margin: 20px;
          box-sizing: border-box;
          box-shadow: -4px -4px 13px $color-whiteStrong, 4px 4px 13px $color-shadowStrong;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          transition: all .2s;
          &:hover{
            box-shadow: -6px -6px 13px $color-whiteStrong, 6px 6px 13px $color-shadowStrong;
            transform: scale(1.05);
          }
        }
        .skill-info {
          color: rgb(204, 204, 204);
          text-align: center;
        }
      }
    }
  }
}
